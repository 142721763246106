import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { paths } from '../../../constants';
import styles from './AddFirstFloorPlan.module.scss';
import AddFloorPlan from './AddFloorPlan';

type Props = {
    locationId: string;
};
const AddFirstFloorPlan = ({ locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const onGoBack = (): void => {
        navigate(generatePath(`/${paths.spaces}`, { buildingId: locationId }));
    };

    return (
        <>
            <PageWrapper pageType="medium">
                <SubHeader actionButtons={[]} backTitle={txt('Return')} onGoBack={onGoBack} />
            </PageWrapper>
            <PageWrapper pageType="slim">
                <h2 className={styles.header}>{txt('SpaceFloorPlan.MapYourSpaces')}</h2>
                <p className={styles.subHeader}>{txt('SpaceFloorPlan.WhatYouGet')}</p>
                <AddFloorPlan locationId={locationId} />
            </PageWrapper>
        </>
    );
};
export default AddFirstFloorPlan;
