import { BusinessRequestType as RequestType } from 'business-dashboard/src/reducers/BusinessRequestType';
import { RequestActionType } from 'commons/src/actions/requestActions';
import { BusinessActionRequestType } from '../models/common';
import { FloorPlanData, SpaceFloorPlan } from '../models/spaceFloorPlanModels';

export enum FloorPlanSpaceActionType {
    GetSpaceFloorPlansInit = 'GET_SPACE_FLOOR_PLANS/INIT',
    GetSpaceFloorPlansSuccess = 'GET_SPACE_FLOOR_PLANS_SUCCESS',
    CreateSpaceFloorPlanInit = 'CREATE_SPACE_FLOOR_PLAN/INIT',
    CreateSpaceFloorPlanSuccess = 'CREATE_SPACE_FLOOR_PLAN_SUCCESS',
    GetFloorPlanDataInit = 'GET_FLOOR_PLAN_DATA/INIT',
    GetFloorPlanDataSuccess = 'GET_FLOOR_PLAN_DATA_SUCCESS',
    DeleteSpaceFloorPlanInit = 'DELETE_SPACE_FLOOR_PLAN/INIT',
    DeleteSpaceFloorPlanSuccess = 'DELETE_SPACE_FLOOR_PLAN_SUCCESS',
    EditSpaceFloorPlanInit = 'EDIT_SPACE_FLOOR_PLAN/INIT',
    EditSpaceFloorPlanSuccess = 'EDIT_SPACE_FLOOR_PLAN_SUCCESS',
}

export interface GetSpaceFloorPlans extends BusinessActionRequestType {
    type: FloorPlanSpaceActionType.GetSpaceFloorPlansInit;
    locationId: string;
}

export const getSpaceFloorPlans = (locationId: string): GetSpaceFloorPlans => ({
    type: FloorPlanSpaceActionType.GetSpaceFloorPlansInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetSpaceFloorPlans,
    locationId,
});

export interface GetSpaceFloorPlansSuccess {
    type: FloorPlanSpaceActionType.GetSpaceFloorPlansSuccess;
    floorPlans: SpaceFloorPlan[];
    locationId: string;
}

export const getSpaceFloorPlansSuccess = (
    floorPlans: SpaceFloorPlan[],
    locationId: string
): GetSpaceFloorPlansSuccess => ({
    type: FloorPlanSpaceActionType.GetSpaceFloorPlansSuccess,
    floorPlans,
    locationId,
});

export interface CreateSpaceFloorPlan extends BusinessActionRequestType {
    type: FloorPlanSpaceActionType.CreateSpaceFloorPlanInit;
    payload: { name: string; image: string; floor: number };
    locationId: string;
}

export const createSpaceFloorPlan = (
    payload: { name: string; image: string; floor: number },
    locationId: string
): CreateSpaceFloorPlan => ({
    type: FloorPlanSpaceActionType.CreateSpaceFloorPlanInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.CreateSpaceFloorPlan,
    payload,
    locationId,
});

export interface CreateSpaceFloorPlanSuccess {
    type: FloorPlanSpaceActionType.CreateSpaceFloorPlanSuccess;
    id: string;
    payload: { name: string; image: string; floor: number };
    locationId: string;
}

export const createSpaceFloorPlanSuccess = (
    id: string,
    payload: { name: string; image: string; floor: number },
    locationId: string
): CreateSpaceFloorPlanSuccess => ({
    type: FloorPlanSpaceActionType.CreateSpaceFloorPlanSuccess,
    id,
    payload,
    locationId,
});

export interface GetFloorPlanData extends BusinessActionRequestType {
    type: FloorPlanSpaceActionType.GetFloorPlanDataInit;
    locationId: string;
    floorPlanId: string;
}

export const getFloorPlanData = (locationId: string, floorPlanId: string): GetFloorPlanData => ({
    type: FloorPlanSpaceActionType.GetFloorPlanDataInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetFloorPlanData,
    locationId,
    floorPlanId,
});

export interface GetFloorPlanDataSuccess {
    type: FloorPlanSpaceActionType.GetFloorPlanDataSuccess;
    floorPlanData: FloorPlanData;
    locationId: string;
    floorPlanId: string;
}

export const getFloorPlanDataSuccess = (
    floorPlanData: FloorPlanData,
    locationId: string,
    floorPlanId: string
): GetFloorPlanDataSuccess => ({
    type: FloorPlanSpaceActionType.GetFloorPlanDataSuccess,
    floorPlanData,
    locationId,
    floorPlanId,
});

export interface DeleteSpaceFloorPlan extends BusinessActionRequestType {
    type: FloorPlanSpaceActionType.DeleteSpaceFloorPlanInit;
    locationId: string;
    floorPlanId: string;
}
export const deleteSpaceFloorPlan = (floorPlanId: string, locationId: string): DeleteSpaceFloorPlan => ({
    type: FloorPlanSpaceActionType.DeleteSpaceFloorPlanInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.DeleteSpaceFloorPlan,
    locationId,
    floorPlanId,
});

export interface DeleteSpaceFloorPlanSuccess {
    type: FloorPlanSpaceActionType.DeleteSpaceFloorPlanSuccess;
    locationId: string;
    floorPlanId: string;
}

export const deleteSpaceFloorPlanSuccess = (floorPlanId: string, locationId: string): DeleteSpaceFloorPlanSuccess => ({
    type: FloorPlanSpaceActionType.DeleteSpaceFloorPlanSuccess,
    locationId,
    floorPlanId,
});

export interface EditSpaceFloorPlan extends BusinessActionRequestType {
    type: FloorPlanSpaceActionType.EditSpaceFloorPlanInit;
    payload: SpaceFloorPlan;
    locationId: string;
    floorPlanId: string;
}
export const editSpaceFloorPlan = (
    payload: SpaceFloorPlan,
    floorPlanId: string,
    locationId: string
): EditSpaceFloorPlan => ({
    type: FloorPlanSpaceActionType.EditSpaceFloorPlanInit,
    requestActionType: RequestActionType.Init,
    requestType: RequestType.EditSpaceFloorPlan,
    payload,
    locationId,
    floorPlanId,
});

export interface EditSpaceFloorPlanSuccess {
    type: FloorPlanSpaceActionType.EditSpaceFloorPlanSuccess;
    payload: SpaceFloorPlan;
    floorPlanId: string;
    locationId: string;
}

export const editSpaceFloorPlanSuccess = (
    payload: SpaceFloorPlan,
    floorPlanId: string,
    locationId: string
): EditSpaceFloorPlanSuccess => ({
    type: FloorPlanSpaceActionType.EditSpaceFloorPlanSuccess,
    payload,
    locationId,
    floorPlanId,
});

export type FloorPlanSpaceActions =
    | GetSpaceFloorPlansSuccess
    | CreateSpaceFloorPlanSuccess
    | GetFloorPlanDataSuccess
    | DeleteSpaceFloorPlanSuccess
    | EditSpaceFloorPlanSuccess;
