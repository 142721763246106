import { t as txt } from 'i18next';
import { DurationInputArg1, DurationInputArg2 } from 'moment';
import moment from 'moment-timezone';
import { colors } from 'commons/src/constants';
import areaRangeData from 'commons/src/features/devicePage/graphDataFunctions';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { ExtraSeries, FullDeviceData, SelectedPeriod, SensorData } from 'commons/src/models/commonTypeScript';
import { addNullPointsToChartData } from '../../../reports/insightFunctions';
import { rangeSeriesName, SpaceGraphData } from './spaceGraphConfig';

const graphColors = [
    colors.greyRiverBed,
    colors.yellowCaramel,
    colors.yellowSepia,
    colors.greenJade,
    colors.greenRacing,
];

const getSpaceGraphData = (
    sensorData: { [segmentId: string]: SensorData },
    extraSeries: { [segmentId: string]: ExtraSeries },
    sensor: SensorTypes,
    selectedInterval: SelectedPeriod,
    deviceData: { [segmentId: string]: FullDeviceData },
    compareSensor?: boolean
): SpaceGraphData[] => {
    const rangeSeries: SpaceGraphData[] = [];
    return (
        Object.keys(sensorData).map((segmentId, index): SpaceGraphData => {
            const sensorExtraSeries = extraSeries[segmentId]?.[sensor]?.[selectedInterval.name] || {};
            const rangeDataForSensor = areaRangeData(sensorExtraSeries, sensor) || [];
            if (rangeDataForSensor && rangeDataForSensor.length > 0) {
                rangeSeries.push({
                    data: rangeDataForSensor as [number, number | null][],
                    id: `range-data-${sensor}-${segmentId}`,
                    name: rangeSeriesName,
                    color: graphColors[index],
                    type: 'arearange',
                    lineWidth: 0,
                    showInLegend: false,
                    opacity: 0.3,
                });
            }
            const graphStartPoint =
                selectedInterval.startDate ||
                moment().subtract(
                    selectedInterval.number as DurationInputArg1,
                    selectedInterval.period as DurationInputArg2
                );
            const graphEndPoint = selectedInterval.endDate || moment();

            return {
                data: addNullPointsToChartData(
                    sensorData[segmentId]?.[sensor]?.[selectedInterval.name] || [],
                    graphStartPoint || null,
                    graphEndPoint || null
                ),
                name: deviceData[segmentId]?.segmentName ?? txt(sensor),
                id: `${sensor}-${segmentId}${compareSensor ? '-compare' : '-main'}`,
                color: graphColors[index],
                dashStyle: compareSensor ? 'dash' : undefined,
                yAxis: compareSensor ? 1 : 0,
            };
        }) || []
    )
        .concat(rangeSeries)
        .filter(x => x.data?.length > 0);
};

export default getSpaceGraphData;
