import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { renameSegment } from 'commons/src/actions/DeviceActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MultipleAttrDropdown, { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { getFirmwareVersionString } from 'commons/src/features/hubs/hubFunctions';
import { PlacementType } from 'commons/src/models/commonEnums';
import { RequestState } from 'commons/src/reducers/requestReducer';
import {
    fetchSpaces,
    moveDeviceBetweenSpaces,
    MoveDevicePayload,
    removeDeviceFromSpace,
} from '../../../../actions/spaceActions';
import { MAX_DEVICES_PER_SPACE } from '../../../../constants';
import { IndoorSpace, SpaceHub } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { spacesSelector } from '../spaceSelectors';
import styles from './HubModals.module.scss';

type Props = {
    hub: SpaceHub;
    onClose: () => void;
    open: boolean;
    locationId: string;
};
export const HubSettingsModal = ({ hub, onClose, open, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = React.useState(hub.segmentName);
    const [loading, setLoading] = React.useState(false);
    const firmwareVersions = getFirmwareVersionString(hub.metaData || {});

    const onSaveName = (): void => {
        setLoading(true);
        dispatch(renameSegment(hub.serialNumber, hub.segmentId, name, locationId, hub.spaceId));
    };
    const renameRequest: RequestState = useSelector((state: Store) => state.commonRequests.RENAME_SEGMENT);

    useEffect(() => {
        if (!renameRequest.loading && loading) {
            setLoading(false);
            if (!renameRequest.error) {
                onClose();
            }
        }
    }, [renameRequest]);
    return (
        <ModalWrapper isOpen={open} onClose={onClose} size="medium" header={txt('HubStrings.HubSettings')}>
            <div className={styles.wrapper}>
                <div className={styles.settingsContent}>
                    <div>
                        {[txt('HubStrings.RadioRegion'), ': ']}
                        <span>{hub.metaData?.region}</span>
                    </div>
                    <div>{[txt('HubStrings.Firmware'), ': ', firmwareVersions]}</div>
                </div>
                <Input
                    type="text"
                    id="hub_name"
                    label={txt('Name')}
                    currentValue={name}
                    validate={name.trim().length < 2}
                    onChange={(e): void => setName(e.currentTarget.value)}
                />
                {renameRequest.error && <ResponseBox text={`ErrorCodes.${renameRequest.error?.error}`} />}
                <div className={styles.buttonContainer}>
                    <PrimaryButton
                        onClick={onSaveName}
                        title={txt('Save')}
                        disabled={name.trim().length < 2}
                        filled
                        loading={renameRequest.loading}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export const HubDeleteModal = ({ hub, onClose, open, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const deleteRequest: RequestState = useSelector((state: Store) => state.requests.REMOVE_DEVICE_FROM_SPACE);
    const onDelete = (): void => {
        setLoading(true);
        const payload = {
            locationId,
            spaceId: hub.spaceId,
            serialNumber: hub.serialNumber,
        };
        dispatch(removeDeviceFromSpace(payload));
    };

    useEffect(() => {
        if (!deleteRequest.loading && loading) {
            setLoading(false);
            if (!deleteRequest.error) {
                onClose();
            }
        }
    }, [deleteRequest]);

    return (
        <ModalWrapper isOpen={open} onClose={onClose} size="medium" header={txt('HubStrings.UnpairHub')}>
            <div className={styles.wrapper}>
                <div className={styles.deleteContent}>
                    {txt('HubStrings.DeleteHubDescription', { name: hub.segmentName })}
                </div>
                {deleteRequest.error && <ResponseBox text={`ErrorCodes.${deleteRequest.error?.error}`} />}
                <div className={styles.buttonContainer}>
                    <PrimaryButton onClick={onClose} title={txt('Cancel')} />
                    <PrimaryButton onClick={onDelete} title={txt('Unpair')} alert loading={deleteRequest.loading} />
                </div>
            </div>
        </ModalWrapper>
    );
};

const MoveHubModal = ({ hub, onClose, open, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [selectedBuilding, setSelectedBuilding] = React.useState<Option | null>(null);
    const [selectedSpace, setSelectedSpace] = React.useState<Option | null>(null);
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const moveRequest: RequestState = useSelector((state: Store) => state.requests.MOVE_DEVICE_BETWEEN_SPACES);
    const {
        spaces: { spaces },
        request,
    } = useSelector((state: Store) => spacesSelector(state, selectedBuilding?.id ?? locationId));

    const { locations } = useSelector((state: Store) => state.locations);
    const locationOptions = useMemo(
        (): Option[] => locations.map(location => ({ id: location.id, inputValue: location.name })),
        [locations]
    );

    const spaceOptions = useMemo((): Option[] => {
        const indoorSpaces: IndoorSpace[] = spaces
            .filter(space => space.placement === PlacementType.ROOM)
            .map(space => space as IndoorSpace);
        return indoorSpaces
            .filter(space => space.id !== hub.spaceId && space.devices.length < MAX_DEVICES_PER_SPACE)
            .map(space => ({ id: space.id, inputValue: space.name }));
    }, [spaces]);

    const selectLocation = (location: Option): void => {
        setSelectedBuilding(location);
        setSelectedSpace(null);
        // Fetch spaces for that location
        if (location.id !== locationId) {
            dispatch(fetchSpaces(location.id));
            setSelectedSpace(null);
        }
    };

    const selectSpace = (space: Option): void => {
        setSelectedSpace(space);
        setName(space.inputValue);
    };

    useEffect(() => {
        const defaultLocation = locationOptions.find(location => location.id === locationId) ?? null;
        setSelectedBuilding(defaultLocation);
    }, [locationId, locationOptions]);

    const onMove = (): void => {
        const newName = name.trim();
        if (selectedBuilding && selectedSpace && newName.length > 2) {
            const payload: MoveDevicePayload = {
                toLocationId: selectedBuilding.id,
                toSpaceId: selectedSpace.id,
                startNewSegment: true,
                deviceName: newName,
            };
            setLoading(true);
            dispatch(
                moveDeviceBetweenSpaces(locationId, hub.spaceId, hub.serialNumber, payload, selectedSpace.inputValue)
            );
        }
    };
    useEffect(() => {
        if (!moveRequest.loading && loading) {
            setLoading(false);
            if (!moveRequest.error) {
                onClose();
            }
        }
    }, [moveRequest]);

    return (
        <ModalWrapper
            isOpen={open}
            onClose={onClose}
            size="medium"
            header={txt('HubStrings.MoveHub', { name: hub.segmentName })}
        >
            <div className={styles.wrapper}>
                <div className={styles.moveContent}>
                    <div className={styles.selectorWrapper}>
                        <MultipleAttrDropdown
                            id="locationSelector"
                            title="Building.Building"
                            options={locationOptions}
                            defaultOption="Select"
                            value={selectedBuilding?.inputValue}
                            onSelect={selectLocation}
                            testAttr="location-dropdown"
                        />
                        <MultipleAttrDropdown
                            id="spaceSelector"
                            title="Space.Space"
                            options={spaceOptions}
                            defaultOption="Select"
                            value={selectedSpace?.inputValue}
                            onSelect={selectSpace}
                            testAttr="space-dropdown"
                            loading={request.loading}
                        />
                    </div>
                    {selectedSpace && (
                        <Input
                            type="text"
                            id="hub_name"
                            label={txt('HubStrings.NewHubName')}
                            currentValue={name}
                            validate={name.trim().length < 2}
                            onChange={(e): void => setName(e.currentTarget.value)}
                        />
                    )}
                    {moveRequest.error && <ResponseBox text={`ErrorCodes.${moveRequest.error?.error}`} />}
                </div>
                <div className={styles.buttonContainer}>
                    <PrimaryButton onClick={onClose} title={txt('Cancel')} />
                    <PrimaryButton
                        onClick={onMove}
                        title={txt('Save')}
                        disabled={!selectedSpace || name.trim().length < 2}
                        filled
                        loading={moveRequest.loading}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default MoveHubModal;
