/* eslint-disable import/prefer-default-export */
/* This file has no default export */

export enum Resolution {
    hour = 'hour',
    fourHours = 'fourHours',
    day = 'day',
    threeDays = 'threeDays',
}

export enum TimePeriod {
    day = 'day',
    yesterday = 'yesterday',
    week = 'week',
    twoWeeks = 'twoWeeks',
    month = 'month',
    custom = 'custom',
}

export enum EnergyEvents {
    warmerOutsideOpeningHours = 'warmerOutsideOpeningHours',
    heatingOnClosedDay = 'heatingOnClosedDay',
    colderOutsideOpeningHours = 'colderOutsideOpeningHours',
    coolingOnClosedDay = 'coolingOnClosedDay',
    colderThan5C = 'colderThan5C',
}

export enum LimitedSubscriptionReasons {
    unpaid = 'unpaid',
    finance_block = 'finance_block',
}

export enum AccountPlanType {
    'STANDARD' = 'STANDARD',
    'INTEGRATION' = 'INTEGRATION',
}
export enum SubscriptionType {
    DEFAULT = 'DEFAULT',
    LIMITED = 'LIMITED',
    ONBOARDING = 'ONBOARDING',
    BLOCKED = 'BLOCKED',
}
export enum ButtonColor {
    primary,
    secondary,
    alert,
    noBackground,
}

export enum NetTypes {
    '4G' = 'catM1',
    '2G' = 'gsm',
}

export enum BleMode {
    Default = 'default',
    Lindab = 'lindab',
}

export enum VirtualDeviceType {
    aggregatedGroup = 'aggregatedGroup',
    inletAirControl = 'inletAirControl',
}

/**
 * Mapping between DeviceTypeNames and DeviceTypes in Zuora.
 * LHS => name for each device type in cloud.
 * RHS => names for each device type in Zuora.
 */
// TODO: fix this we should not set naming multiple places
export enum SubscriptionDeviceTypeNames {
    hub = 'Space Hub',
    waveMini = 'Wave Mini',
    viewPlusBusiness = 'Space Pro',
    spaceCo2Mini = 'Space CO2 Mini',
    viewCo2 = 'Space CO2',
    waveCo2 = 'Wave CO2',
    wavePlus = 'Space Plus',
}

export enum DeviceTypeNames {
    hub = 'hub',
    wavePlus = 'wavePlus',
    waveCo2 = 'waveCo2',
    waveEnhance = 'waveEnhance',
    wave = 'wave',
    wave2 = 'wave2',
    mini = 'waveMini',
    mist = 'waveMist',
    ternCo2 = 'ternCo2',
    spaceCo2Mini = 'spaceCo2Mini',
    viewPlus = 'viewPlus',
    viewPlusBusiness = 'viewPlusBusiness',
    viewPollution = 'viewPollution',
    viewRadon = 'viewRadon',
    viewCo2 = 'viewCo2',
    home = 'home',
    bacnet = 'bacnet',
    spacePlus = 'spacePlus',
    spaceHub = 'spaceHub',
    waveNano = 'waveNano',
    spaceNano = 'spaceNano',
    ravenRadon = 'ravenRadon',
    ap1 = 'ap1',
}

export enum InviteStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
}

export enum FeatureToggleEnum {
    partnerWithConsumerCustomers = 'partner_with_consumer_customers',
    partnerInsightSummary = 'partner_insight_summary',
    oldPartnerView = 'old_partner_view',
    quicksightIntegration = 'quicksight_integration',
    enableCustomThresholds = 'enable_custom_thresholds',
}

export enum GroupType {
    business = 'business',
    partner = 'partner',
    consumer = 'consumer',
}

export enum DashboardVisibility {
    user = 'user',
    userGroup = 'userGroup',
    userGroupPublic = 'userGroupPublic',
}

export enum LedBrightness {
    off = 'off',
    low = 'low',
    medium = 'medium',
    high = 'high',
}

export enum ConsumerBatteryModes {
    increasedDataFrequency = 'increasedDataFrequency',
    improvedBatteryLife = 'improvedBatteryLife',
}

export enum LedBehavioursWavePlus {
    alarmStaleAir = 'alarmStaleAir',
    thresholdWave = 'thresholdWave',
    connectedStatus = 'connectedStatus',
}

export enum EnumStatusColors {
    green = 'green',
    yellow = 'yellow',
    red = 'red',
    grey = 'grey',
}

export enum Rating {
    GOOD = 'GOOD',
    FAIR = 'FAIR',
    POOR = 'POOR',
}

export enum RequiredRoleLevel {
    ABOVE_ADMIN = 'ABOVE_ADMIN',
    ABOVE_EDITOR = 'ABOVE_EDITOR',
    SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
    ANY_ROLE = 'ANY_ROLE',
}

export enum Role {
    SUPPORT = 'SUPPORT',
    ADMIN = 'ADMIN',
    USER = 'USER',
    VIEWER = 'VIEWER',
    // Special account types (not for common users):
    SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
}

export enum SensorTypes {
    radon = 'radon',
    radonShortTermAvg = 'radonShortTermAvg',
    hourlyRadon = 'hourlyRadon',
    pm1 = 'pm1',
    outdoorPm1 = 'outdoorPm1',
    pm25 = 'pm25',
    outdoorPm25 = 'outdoorPm25',
    pm10 = 'pm10',
    outdoorPm10 = 'outdoorPm10',
    voc = 'voc',
    co2 = 'co2',
    humidity = 'humidity',
    outdoorHumidity = 'outdoorHumidity',
    temp = 'temp',
    outdoorTemp = 'outdoorTemp',
    pressure = 'pressure',
    outdoorPressure = 'outdoorPressure',
    noise = 'noise',
    sla = 'sla',
    light = 'light',
    lux = 'lux',
    mold = 'mold',
    virusRisk = 'virusRisk',
    staleAir = 'staleAir',
    transmissionEfficiency = 'transmissionEfficiency',
    virusSurvivalRate = 'virusSurvivalRate',
    occupants = 'occupants',
    hourlyRadonStandardDeviation = 'hourlyRadonStandardDeviation',
    occupancy = 'occupancy',
    presence = 'presence',
    ventilationAmount = 'ventilationAmount',
    ventilationRate = 'ventilationRate',
    airExchangeRate = 'airExchangeRate',
    ventilationRunning = 'ventilationRunning',
    historicVentilationRunning = 'historicVentilationRunning',
    controlSignal = 'controlSignal',
    totalPowerLost = 'totalPowerLost',
    moistGuard = 'moistGuard',
    potentialPowerSaved = 'potentialPowerSaved',
    potentialPowerSavedPercent = 'potentialPowerSavedPercent',
    regulationHeight = 'regulationHeight',
    regulationPressure = 'regulationPressure',
    zeroHeightPercent = 'zeroHeightPercent',
}

export enum ScreenOptions {
    co2 = 'co2',
    publicQr = 'publicQr',
    weatherAndTemp = 'weatherAndTemp',
    blank = 'blank',
    noise = 'noise',
    co2TempHumidity = 'co2TempHumidity',
}

export type ViewDisplayUnits = {
    radon: string;
    temperature: string;
    pressure: string;
    voc: string;
};

export enum IdentityProvider {
    Google = 'Google',
    Microsoft = 'Microsoft',
    Other = 'Other',
}

export enum VerificationState {
    Verified = 'Verified',
    NotVerified = 'NotVerified',
    Waiting = 'Waiting',
    Unsuccessful = 'Unsuccessful',
}

export enum DomainState {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum PlacementType {
    ROOM = 'ROOM',
    TECHNICAL_SPACE = 'TECHNICAL_SPACE',
    OUTDOOR = 'OUTDOOR',
}

export enum PartnerCustomerSubscriptionStatus {
    Standard = 'Standard',
    Limited = 'Limited',
    InsufficientSeats = 'InsufficientSeats',
    Active = 'Active',
    NoDevices = 'NoDevices',
    NotFound = 'NotFound',
}

export enum PillStatus { // these are also translated so a change here requires change in poEditor
    success = 'success',
    warning = 'warning',
    alert = 'alert',
    info = 'info',
    neutral = 'neutral',
}

export enum PredefinedProperty {
    Custom = 'CUSTOM',
    RoomType = 'ROOM_TYPE',
    Floor = 'FLOOR',
    Area = 'AREA',
    VentilationZone = 'VENTILATION_ZONE',
    Height = 'HEIGHT',
}

export enum PropertyType {
    Selection = 'SELECTION',
    Number = 'NUMBER',
}

export type ValueOf<T> = T[keyof T];
