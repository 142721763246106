import React, { SyntheticEvent, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { linkToAirthingsAfBReadme, linkToApiDocs } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { SetSegmentLabels, setSegmentLabels } from '../../../actions/segmentPropertiesActions';
import { KeyValuePairType } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import MapInput, { pairsWithError } from '../../integrations/webhooks/MapInput';

type StateProps = {
    segmentLabels: {
        [serialNumber: string]: KeyValuePairType[];
    };
    loading: boolean;
    error?: ErrorType;
};

type ActionProps = {
    onSetSegmentLabels: (serialNumber: string, labels: KeyValuePairType[]) => void;
};

type PassedProps = {
    serialNumber: string;
    goBack: (e: SyntheticEvent<HTMLElement>) => void;
};

type Props = PassedProps & ActionProps & StateProps;

export const DeviceLabelsComponent = (props: Props): React.ReactElement => {
    const { onSetSegmentLabels, segmentLabels, goBack, error, loading, serialNumber } = props;

    const [labels, setLabels] = useState(segmentLabels[serialNumber] || []);
    const [displayValidation, setDisplayValidation] = useState(false);

    const updateLabels = (updatedLabels: KeyValuePairType[]): void => {
        setLabels(updatedLabels);
    };

    const saveSegmentLabels = (): void => {
        const errorInLabels = pairsWithError(labels);
        if (errorInLabels) {
            setDisplayValidation(true);
        } else {
            setDisplayValidation(false);
            onSetSegmentLabels(serialNumber, labels);
        }
    };

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="change-location__form">
                <p>
                    <Trans i18nKey="CustomLabels.CustomLabelsDescription" values={{ link: linkToAirthingsAfBReadme }}>
                        <a href={linkToApiDocs} target="_blank" rel="noopener noreferrer">
                            {linkToAirthingsAfBReadme}
                        </a>
                    </Trans>
                </p>
                <div className="change-location__form__labels">
                    <MapInput
                        id="segment-labels-selector"
                        updatePair={updateLabels}
                        pairs={labels}
                        displayValidation={displayValidation}
                        keyHeader="CustomLabels.LabelKey"
                        keyHint="CustomLabels.LabelKeyRequiredHint"
                        valueHeader="CustomLabels.LabelValue"
                        valueHint=""
                        addButtonText="CustomLabels.AddLabel"
                        maxValueLength={255}
                        maxKeyLength={255}
                        maxRows={20}
                    />
                </div>
                {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
                <div className="change-location__form__buttons">
                    <PrimaryButton id="close" title="Close" loading={false} onClick={goBack} />
                    <PrimaryButton
                        id="submit"
                        type="button"
                        title="SaveChanges"
                        filled
                        loading={loading}
                        onClick={saveSegmentLabels}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        segmentPropertiesStore: { segmentLabels },
        requests: {
            [RequestType.UpdateSegmentLabels]: { loading, error },
        },
    } = state;

    return {
        segmentLabels,
        loading,
        error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onSetSegmentLabels: (serialNumber: string, labels: KeyValuePairType[]): SetSegmentLabels =>
        dispatch(setSegmentLabels(serialNumber, labels)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceLabelsComponent);
