import { LocationAction } from 'commons/src/actions/LocationActions';
import { clearError, RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { BusinessRequestTypesUsedInCommons, reducer, RequestState } from 'commons/src/reducers/requestReducer';
import { FloorplanAction } from '../actions/floorplanActions';
import { HealthStatusActions } from '../actions/healthStatusActions';
import { InsightAction } from '../actions/insightActions';
import { WebhookAction } from '../actions/integrationActions';
import { UpdateLocationDetails } from '../actions/locationActions';
import { MqttActions } from '../actions/mqttIntegrationActions';
import { OrganizationMemberAction } from '../actions/organizationMemberActions';
import { OrganizationPropertiesAction } from '../actions/organizationPropertiesActions';
import { PartnerAction } from '../actions/partnerActions';
import { PartnerCustomerAction } from '../actions/partnerCustomerActions';
import { ThirdPartyIntegrationAction } from '../actions/thirdPartyIntegrationActions';
import { BusinessRequestType } from './BusinessRequestType';

export const REQUEST_INIT = RequestActionType.Init;

export type RequestInitActions =
    | FloorplanAction
    | InsightAction
    | LocationAction
    | OrganizationPropertiesAction
    | OrganizationMemberAction
    | UpdateLocationDetails
    | ThirdPartyIntegrationAction
    | WebhookAction
    | PartnerAction
    | HealthStatusActions
    | PartnerCustomerAction
    | MqttActions;

export type RequestAction =
    | RequestInitActions
    | ReturnType<typeof requestError>
    | ReturnType<typeof requestSuccess>
    | ReturnType<typeof clearError>;

export type BusinessRequestStore = {
    [K in BusinessRequestType | BusinessRequestTypesUsedInCommons]: RequestState;
};

const initialStateObject: RequestState = {
    loading: false,
    error: undefined,
    streaming: false,
};

export const initialStore: BusinessRequestStore = {
    [BusinessRequestTypesUsedInCommons.AddLocation]: initialStateObject,
    [BusinessRequestType.AddEmailTrigger]: initialStateObject,
    [BusinessRequestType.AddSeatsToSubscription]: initialStateObject,
    [BusinessRequestType.AddThirdPartyAlert]: initialStateObject,
    [BusinessRequestType.AddThirdPartyIntegration]: initialStateObject,
    [BusinessRequestType.AddWebhooks]: initialStateObject,
    [BusinessRequestType.AddZoneGroup]: initialStateObject,
    [BusinessRequestType.BuildingsThresholdsSetFocusedSensor]: initialStateObject,
    [BusinessRequestType.BuildingsThresholdsUpdateConfig]: initialStateObject,
    [BusinessRequestType.BuildingsThresholdsUnsetDefault]: initialStateObject,
    [BusinessRequestType.ChangeMembershipRole]: initialStateObject,
    [BusinessRequestType.CreatePartnerCustomer]: initialStateObject,
    [BusinessRequestType.CreateSpace]: initialStateObject,
    [BusinessRequestType.CreateSpaceFloorPlan]: initialStateObject,
    [BusinessRequestType.CreateSubscriptionForDirectCustomer]: initialStateObject,
    [BusinessRequestType.CreateSubscriptionForPartnerCustomer]: initialStateObject,
    [BusinessRequestType.CreateThresholdBreachInsight]: initialStateObject,
    [BusinessRequestType.DeleteInvite]: initialStateObject,
    [BusinessRequestTypesUsedInCommons.DeleteLocation]: initialStateObject,
    [BusinessRequestType.DeleteOrganizationLogo]: initialStateObject,
    [BusinessRequestType.DeleteNotificationAlert]: initialStateObject,
    [BusinessRequestType.DeleteSpace]: initialStateObject,
    [BusinessRequestType.DeleteSpaceFloorPlan]: initialStateObject,
    [BusinessRequestType.DeleteThirdPartyIntegration]: initialStateObject,
    [BusinessRequestType.DeleteThresholdBreachInsight]: initialStateObject,
    [BusinessRequestType.DeleteWebhook]: initialStateObject,
    [BusinessRequestType.EditSpace]: initialStateObject,
    [BusinessRequestType.EditSpaceFloorPlan]: initialStateObject,
    [BusinessRequestType.FetchAlertEvents]: initialStateObject,
    [BusinessRequestType.FetchIaqInsightData]: initialStateObject,
    [BusinessRequestType.FetchBuildingWithDevices]: initialStateObject,
    [BusinessRequestType.FetchBuilding]: initialStateObject,
    [BusinessRequestType.FetchLocationFloorplans]: initialStateObject,
    [BusinessRequestType.FetchLocationResetConnections]: initialStateObject,
    [BusinessRequestType.FetchFloorplanImage]: initialStateObject,
    [BusinessRequestType.FetchLocationLabels]: initialStateObject,
    [BusinessRequestType.FetchOutdoorData]: initialStateObject,
    [BusinessRequestType.FetchPartnerDetails]: initialStateObject,
    [BusinessRequestType.FetchSegmentPropertyFilters]: initialStateObject,
    [BusinessRequestType.FetchRadonInsightData]: initialStateObject,
    [BusinessRequestType.FetchOutdoorInsightData]: initialStateObject,
    [BusinessRequestType.FetchSegmentProperties]: initialStateObject,
    [BusinessRequestType.FetchSegmentLabels]: initialStateObject,
    [BusinessRequestType.FetchGeneratedSegmentPublicUrl]: initialStateObject,
    [BusinessRequestType.FetchSpaces]: initialStateObject,
    [BusinessRequestType.FetchSpaceSensorData]: initialStateObject,
    [BusinessRequestType.FetchSpaceVirtualSensorData]: initialStateObject,
    [BusinessRequestType.FetchThirdPartyIntegrationClientID]: initialStateObject,
    [BusinessRequestType.FetchThirdPartyIntegrationMapping]: initialStateObject,
    [BusinessRequestType.FetchThirdPartyIntegrations]: initialStateObject,
    [BusinessRequestType.FetchThresholdBreachAllInsights]: initialStateObject,
    [BusinessRequestType.FetchThresholdBreachSingleInsight]: initialStateObject,
    [BusinessRequestType.FetchVirusRiskInsightData]: initialStateObject,
    [BusinessRequestType.FetchWebhookEvents]: initialStateObject,
    [BusinessRequestType.FetchWebhooks]: initialStateObject,
    [BusinessRequestType.GetBuildingsFocusedSensorData]: initialStateObject,
    [BusinessRequestType.GetBuildingsThresholdsFocusedSensor]: initialStateObject,
    [BusinessRequestType.GetHeatingCoolingData]: initialStateObject,
    [BusinessRequestType.GetLocationConfiguration]: initialStateObject,
    [BusinessRequestType.GetOrganizationLogo]: initialStateObject,
    [BusinessRequestType.GetOrganizationMembers]: initialStateObject,
    [BusinessRequestType.GetPresenceData]: initialStateObject,
    [BusinessRequestType.GetPresenceSensorData]: initialStateObject,
    [BusinessRequestType.GetPresenceWeekAggregation]: initialStateObject,
    [BusinessRequestType.GetFloorPlanData]: initialStateObject,
    [BusinessRequestType.GetSpaceFloorPlans]: initialStateObject,
    [BusinessRequestType.GetSubscriptionForEndCustomer]: initialStateObject,
    [BusinessRequestType.GetSubscriptionForPartnerCustomer]: initialStateObject,
    [BusinessRequestType.RegisterThirdPartyIntegrationMapping]: initialStateObject,
    [BusinessRequestType.RemoveMember]: initialStateObject,
    [BusinessRequestType.ResendInvite]: initialStateObject,
    [BusinessRequestType.SendInvite]: initialStateObject,
    [BusinessRequestType.SetDevicePubliclyAvailable]: initialStateObject,
    [BusinessRequestType.SetActiveFloorplan]: initialStateObject,
    [BusinessRequestType.SetFloorplanModalVisisble]: initialStateObject,
    [BusinessRequestType.TestWebhook]: initialStateObject,
    [BusinessRequestType.TogglePartnerBranding]: initialStateObject,
    [BusinessRequestType.UpdateAlertActiveState]: initialStateObject,
    [BusinessRequestType.UpdateEmailTrigger]: initialStateObject,
    [BusinessRequestType.UpdateFloorName]: initialStateObject,
    [BusinessRequestType.UpdateLocationConfiguration]: initialStateObject,
    [BusinessRequestType.UpdateLocationConfigurationWithDeviceType]: initialStateObject,
    [BusinessRequestType.UpdateLocationDetails]: initialStateObject,
    [BusinessRequestType.UpdateLocationLabels]: initialStateObject,
    [BusinessRequestType.UpdateOrganizationLogo]: initialStateObject,
    [BusinessRequestType.UpdateOrganizationProperties]: initialStateObject,
    [BusinessRequestType.UpdatePartnerCustomer]: initialStateObject,
    [BusinessRequestType.UpdatePoRef]: initialStateObject,
    [BusinessRequestType.UpdateSegmentProperties]: initialStateObject,
    [BusinessRequestType.UpdateSegmentLabels]: initialStateObject,
    [BusinessRequestType.UpdateThirdPartyAlert]: initialStateObject,
    [BusinessRequestType.UpdateThirdPartyIntegrationMapping]: initialStateObject,
    [BusinessRequestType.UpdateThresholdBreachConfig]: initialStateObject,
    [BusinessRequestType.UpdateWebhook]: initialStateObject,
    [BusinessRequestType.UpdateWebhookActiveState]: initialStateObject,
    [BusinessRequestType.FetchMqttClients]: initialStateObject,
    [BusinessRequestType.AddMqttClient]: initialStateObject,
    [BusinessRequestType.DeleteMqttClient]: initialStateObject,
    [BusinessRequestType.FetchMqttClientCertificate]: initialStateObject,
    [BusinessRequestType.UploadMqttClientCertificate]: initialStateObject,
    [BusinessRequestType.UpdateMqttClient]: initialStateObject,
    [BusinessRequestType.UpdateMqttClientActiveState]: initialStateObject,
    [BusinessRequestType.RenewMqttClientCertificate]: initialStateObject,
    [BusinessRequestType.DeleteMqttClientCertificate]: initialStateObject,
    [BusinessRequestType.FetchBuildingHealthStatus]: initialStateObject,
    [BusinessRequestType.FetchOrganizationHealthStatus]: initialStateObject,
    [BusinessRequestType.FetchPartnerOfflineDevices]: initialStateObject,
    [BusinessRequestType.CreateFederatedClient]: initialStateObject,
    [BusinessRequestType.EditFederatedClient]: initialStateObject,
    [BusinessRequestType.ToggleActiveFederatedClient]: initialStateObject,
    [BusinessRequestType.GetFederatedClients]: initialStateObject,
    [BusinessRequestType.DeleteSsoClient]: initialStateObject,
    [BusinessRequestType.VerifySsoClientDomain]: initialStateObject,
    [BusinessRequestType.DownloadSensorDataCsv]: initialStateObject,
    [BusinessRequestType.FetchBuildingHealthSummary]: initialStateObject,
    [BusinessRequestType.FetchBuildingHealthHubsStats]: initialStateObject,
    [BusinessRequestType.FetchBuildingHealthDevicesStats]: initialStateObject,
    [BusinessRequestType.FetchOrganizationHealth]: initialStateObject,
    [BusinessRequestType.FetchOrganizationHealthStatusSummary]: initialStateObject,
    [BusinessRequestType.AddDeviceToSpace]: initialStateObject,
    [BusinessRequestType.FetchHealthStatusIssueData]: initialStateObject,
    [BusinessRequestType.RemoveDeviceFromSpace]: initialStateObject,
    [BusinessRequestType.MoveDeviceBetweenSpaces]: initialStateObject,
    [BusinessRequestType.MigrateToSpaces]: initialStateObject,
};

export default reducer(initialStore);
