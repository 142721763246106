import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import SpinnerBlocker from 'commons/src/components/modals/ModalSpinnerBlocker';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { FloorPlanData } from '../../../models/spaceFloorPlanModels';
import { Store } from '../../../reducers';
import AddFloorPlan from '../addFloorPlan/AddFloorPlan';
import EditFloorPlanDetails from '../addFloorPlan/EditFloorPlanDetails';
import FloorMap from './FloorMap';
import styles from './FloorPlanView.module.scss';

export type Props = {
    floorPlanId?: string;
    floorPlanData?: FloorPlanData;
    addingNewFloor: boolean;
    locationId: string;
};
const FloorPlanView = ({ floorPlanId, floorPlanData, addingNewFloor, locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const floorPlans = useSelector((state: Store) => state.floorPlansSpace.floorPlans);
    const selectedFloorPlan = floorPlans.find(floorPlan => floorPlan.id === floorPlanId);
    const { loading, error } = useSelector((state: Store) => state.requests.GET_FLOOR_PLAN_DATA);

    useEffect(() => {
        setIsEditing(false);
    }, [floorPlanId]);

    return (
        <div className={styles.wrapper}>
            {selectedFloorPlan && !addingNewFloor ? (
                <>
                    <div className={styles.header}>
                        {isEditing ? (
                            <EditFloorPlanDetails
                                locationId={locationId}
                                floorPlan={selectedFloorPlan}
                                onClose={(): void => setIsEditing(false)}
                            />
                        ) : (
                            <>
                                <div className={styles.floorNr}>
                                    {txt('SpaceFloorPlan.FloorNr', { floorNr: selectedFloorPlan.floor })}
                                </div>
                                <div className={styles.floorName}>{selectedFloorPlan.name}</div>
                                <TertiaryButton
                                    onClick={(): void => setIsEditing(true)}
                                    title={txt('Edit')}
                                    icon={<MaterialIcon name="edit" />}
                                    size="medium"
                                    testId="data-edit-floor-details"
                                />
                            </>
                        )}
                    </div>
                    <SpinnerBlocker isLoading={loading && !error} className={styles.spinnerBlock}>
                        {floorPlanData?.image ? (
                            <FloorMap image={floorPlanData.image} />
                        ) : (
                            <ResponseBox text={`ErrorCodes.${error?.error}`} />
                        )}
                    </SpinnerBlocker>
                </>
            ) : (
                <div className={styles.newFloorPlanWrapper}>
                    <PageWrapper pageType="slim">
                        <h3 className={styles.newFloorPlanHeader}>{txt('SpaceFloorPlan.AddNewFloorPlan')}</h3>
                    </PageWrapper>
                    <AddFloorPlan locationId={locationId} />
                </div>
            )}
        </div>
    );
};
export default FloorPlanView;
