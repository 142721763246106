export enum BusinessRequestType {
    AddEmailTrigger = 'ADD_EMAIL_TRIGGER',
    AddSeatsToSubscription = 'ADD_SEATS_TO_SUBSCRIPTION',
    AddThirdPartyAlert = 'ADD_THIRD_PARTY_ALERT',
    AddThirdPartyIntegration = 'ADD_THIRD_PARTY_INTEGRATION',
    AddWebhooks = 'ADD_WEBHOOK',
    AddZoneGroup = 'ADD_ZONE_GROUP',
    BuildingsThresholdsSetFocusedSensor = 'BUILDINGS_THRESHOLDS_SET_FOCUSED_SENSOR',
    BuildingsThresholdsUpdateConfig = 'BUILDINGS_THRESHOLDS_UPDATE_CONFIG',
    BuildingsThresholdsUnsetDefault = 'BUILDINGS_THRESHOLDS_UNSET_DEFAULT',
    ChangeMembershipRole = 'CHANGE_MEMBERSHIP_ROLE',
    CreatePartnerCustomer = 'CREATE_PARTNER_CUSTOMER',
    CreateSpace = 'CREATE_SPACE',
    CreateSpaceFloorPlan = 'CREATE_SPACE_FLOOR_PLAN',
    CreateSubscriptionForDirectCustomer = 'CREATE_SUBSCRIPTION_FOR_DIRECT_CUSTOMER',
    CreateSubscriptionForPartnerCustomer = 'CREATE_SUBSCRIPTION_FOR_PARTNER_CUSTOMER',
    CreateThresholdBreachInsight = 'CREATE_THRESHOLD_BREACH_INSIGHT',
    DeleteInvite = 'DELETE_INVITE',
    DeleteNotificationAlert = 'DELETE_NOTIFICATION_ALERT',
    DeleteOrganizationLogo = 'DELETE_ORGANIZATION_LOGO',
    DeleteSpace = 'DELETE_SPACE',
    DeleteSpaceFloorPlan = 'DELETE_SPACE_FLOOR_PLAN',
    DeleteThirdPartyIntegration = 'DELETE_THIRD_PARTY_INTEGRATION',
    DeleteThresholdBreachInsight = 'DELETE_THRESHOLD_BREACH_INSIGHT',
    DeleteWebhook = 'DELETE_WEBHOOK',
    EditSpace = 'EDIT_SPACE',
    EditSpaceFloorPlan = 'EDIT_SPACE_FLOOR_PLAN',
    FetchAlertEvents = 'FETCH_ALERT_EVENTS',
    FetchIaqInsightData = 'FETCH_IAQ_INSIGHT_DATA',
    FetchFloorplanImage = 'FETCH_FLOORPLAN_IMAGE',
    FetchBuildingWithDevices = 'FETCH_BUILDING_WITH_DEVICES',
    FetchBuilding = 'FETCH_BUILDING',
    FetchBuildingHealthStatus = 'FETCH_BUILDING_HEALTH_STATUS',
    FetchOrganizationHealthStatus = 'FETCH_BUILDINGS_HEALTH_STATUS',
    FetchLocationFloorplans = 'FETCH_LOCATION_FLOORPLANS',
    FetchLocationLabels = 'FETCH_LOCATION_LABELS',
    FetchOutdoorData = 'FETCH_OUTDOOR_DATA',
    FetchRadonInsightData = 'FETCH_RADON_INSIGHT_DATA',
    FetchOutdoorInsightData = 'FETCH_OUTDOOR_INSIGHT_DATA',
    FetchPartnerDetails = 'FETCH_PARTNER_DETAILS',
    FetchSegmentProperties = 'FETCH_SEGMENT_PROPERTIES',
    FetchSegmentLabels = 'FETCH_SEGMENT_LABELS',
    FetchSegmentPropertyFilters = 'FETCH_SEGMENT_PROPERTY_FILTERS',
    FetchSpaces = 'FETCH_SPACES',
    FetchSpaceSensorData = 'FETCH_SPACE_SENSOR_DATA',
    FetchSpaceVirtualSensorData = 'FETCH_SPACE_VIRTUAL_SENSOR_DATA',
    FetchGeneratedSegmentPublicUrl = 'FETCH_GENERATED_SEGMENT_PUBLIC_URL',
    FetchThirdPartyIntegrationClientID = 'FETCH_THIRD_PARTY_INTEGRATION_CLIENT_ID',
    FetchThirdPartyIntegrationMapping = 'FETCH_THIRD_PARTY_INTEGRATION_MAPPING',
    FetchThirdPartyIntegrations = 'FETCH_THIRD_PARTY_INTEGRATIONS',
    FetchThresholdBreachAllInsights = 'FETCH_THRESHOLD_BREACH_ALL_INSIGHTS',
    FetchThresholdBreachSingleInsight = 'FETCH_THRESHOLD_BREACH_SINGLE_INSIGHT',
    FetchVirusRiskInsightData = 'FETCH_VIRUS_RISK_INSIGHT_DATA',
    FetchWebhooks = 'FETCH_WEBHOOKS',
    GetLocationConfiguration = 'GET_LOCATION_CONFIG',
    GetBuildingsFocusedSensorData = 'GET_BUILDINGS_FOCUSED_SENSOR_DATA',
    GetFloorPlanData = 'GET_FLOOR_PLAN_DATA',
    GetSpaceFloorPlans = 'GET_SPACE_FLOOR_PLANS',
    GetSubscriptionForEndCustomer = 'GET_SUBSCRIPTION_FOR_END_CUSTOMER',
    GetSubscriptionForPartnerCustomer = 'GET_SUBSCRIPTION_FOR_PARTNER_CUSTOMER',
    FetchLocationResetConnections = 'FETCH_LOCATION_RESET_CONNECTIONS',
    GetBuildingsThresholdsFocusedSensor = 'GET_BUILDINGS_THRESHOLDS_FOCUSED_SENSOR',
    GetHeatingCoolingData = 'GET_HEATING_COOLING_DATA',
    GetOrganizationLogo = 'GET_ORGANIZATION_LOGO',
    GetOrganizationMembers = 'GET_ORGANIZATION_MEMBERS',
    GetPresenceData = 'GET_PRESENCE_DATA',
    GetPresenceSensorData = 'GET_PRESENCE_SENSOR_DATA',
    GetPresenceWeekAggregation = 'GET_PRESENCE_WEEK_AGGREGATION',
    FetchWebhookEvents = 'FETCH_WEBHOOK_EVENTS',
    RegisterThirdPartyIntegrationMapping = 'REGISTER_THIRD_PARTY_INTEGRATION_MAPPING',
    RemoveMember = 'REMOVE_MEMBER',
    ResendInvite = 'RESEND_INVITE',
    SendInvite = 'SEND_INVITE',
    SetDevicePubliclyAvailable = 'SET_DEVICE_PUBLICLY_AVAILABLE',
    SetActiveFloorplan = 'SET_ACTIVE_FLOORPLAN',
    SetFloorplanModalVisisble = 'SET_FLOORPLAN_MODAL_VISIBLE',
    TestWebhook = 'TEST_WEBHOOK',
    TogglePartnerBranding = 'TOGGLE_PARTNER_BRANDING',
    UpdateLocationDetails = 'UPDATE_LOCATION_DETAILS',
    UpdateLocationLabels = 'UPDATE_LOCATION_LABELS',
    UpdateFloorName = 'UPDATE_FLOOR_NAME',
    UpdateAlertActiveState = 'UPDATE_ALERT_ACTIVE_STATE',
    UpdateEmailTrigger = 'UPDATE_EMAIL_TRIGGER',
    UpdateLocationConfiguration = 'UPDATE_LOCATION_CONFIG',
    UpdateLocationConfigurationWithDeviceType = 'UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE',
    UpdateOrganizationLogo = 'UPDATE_ORGANIZATION_LOGO',
    UpdateOrganizationProperties = 'UPDATE_ORGANIZATION_PROPERTIES',
    UpdatePartnerCustomer = 'UPDATE_PARTNER_CUSTOMER',
    UpdatePoRef = 'UPDATE_PO_REF',
    UpdateSegmentProperties = 'UPDATE_SEGMENT_PROPERTIES',
    UpdateSegmentLabels = 'UPDATE_SEGMENT_LABELS',
    UpdateThirdPartyAlert = 'UPDATE_THIRD_PARTY_ALERT',
    UpdateThirdPartyIntegrationMapping = 'UPDATE_THIRD_PARTY_INTEGRATION_MAPPING',
    UpdateThresholdBreachConfig = 'UPDATE_THRESHOLD_BREACH_CONFIG',
    UpdateWebhook = 'UPDATE_WEBHOOK',
    UpdateWebhookActiveState = 'UPDATE_WEBHOOK_ACTIVE_STATE',
    FetchMqttClients = 'FETCH_MQTT_CLIENTS',
    AddMqttClient = 'ADD_MQTT_CLIENT',
    UpdateMqttClientActiveState = 'UPDATE_MQTT_CLIENT_ACTIVE_STATE',
    DeleteMqttClient = 'DELETE_MQTT_CLIENT',
    FetchMqttClientCertificate = 'FETCH_MQTT_CLIENT_CERTIFICATE',
    UploadMqttClientCertificate = 'UPLOAD_MQTT_CLIENT_CERTIFICATE',
    UpdateMqttClient = 'UPDATE_MQTT_CLIENT',
    RenewMqttClientCertificate = 'RENEW_MQTT_CLIENT_CERTIFICATE',
    DeleteMqttClientCertificate = 'DELETE_MQTT_CLIENT_CERTIFICATE',
    FetchPartnerOfflineDevices = 'FETCH_PARTNER_OFFLINE_DEVICES',
    CreateFederatedClient = 'CREATE_FEDERATED_CLIENT',
    EditFederatedClient = 'EDIT_FEDERATED_CLIENT',
    ToggleActiveFederatedClient = 'TOGGLE_ACTIVE_FEDERATED_CLIENT',
    GetFederatedClients = 'GET_FEDERATED_CLIENTS',
    DeleteSsoClient = 'DELETE_SSO_CLIENT',
    VerifySsoClientDomain = 'VERIFY_SSO_CLIENT_DOMAIN',
    DownloadSensorDataCsv = 'DOWNLOAD_SENSOR_DATA_CSV',
    FetchBuildingHealthSummary = 'FETCH_BUILDING_HEALTH_SUMMARY',
    FetchBuildingHealthHubsStats = 'FETCH_BUILDING_HEALTH_HUBS_STATS',
    FetchBuildingHealthDevicesStats = 'FETCH_BUILLDING_HEALTH_DEVICES_STATS',
    FetchOrganizationHealth = 'FETCH_ORGANIZATION_HEALTH',
    FetchOrganizationHealthStatusSummary = 'FETCH_ORGANIZATION_HEALTH_STATUS_SUMMARY',
    FetchHealthStatusIssueData = 'FETCH_HEALTH_STATUS_ISSUE_DATA',
    AddDeviceToSpace = 'ADD_DEVICE_TO_SPACE',
    RemoveDeviceFromSpace = 'REMOVE_DEVICE_FROM_SPACE',
    MoveDeviceBetweenSpaces = 'MOVE_DEVICE_BETWEEN_SPACES',
    MigrateToSpaces = 'MIGRATE_TO_SPACES',
}

export default BusinessRequestType;
