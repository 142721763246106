import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import RequestActions from 'commons/src/models/RequestTypes';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import {
    CreateSpaceFloorPlan,
    createSpaceFloorPlanSuccess,
    CreateSpaceFloorPlanSuccess,
    DeleteSpaceFloorPlan,
    deleteSpaceFloorPlanSuccess,
    EditSpaceFloorPlan,
    editSpaceFloorPlanSuccess,
    FloorPlanSpaceActionType,
    GetFloorPlanData,
    getFloorPlanDataSuccess,
    GetSpaceFloorPlans,
    getSpaceFloorPlansSuccess,
    GetSpaceFloorPlansSuccess,
} from '../actions/floorPlanSpaceActions';
import {
    deleteFloorPlan,
    editFloorPlan,
    getFloorPlanDetails,
    getFloorPlans,
    uploadFloorPlan,
} from '../api/floorPlanSpaceAPI';
import { FloorPlanData, SpaceFloorPlan } from '../models/spaceFloorPlanModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

type GetSpaceFloorPlanSaga = Generator<
    CallEffect | PutEffect<GetSpaceFloorPlansSuccess> | CallEffect<{ floorPlans: SpaceFloorPlan[] }> | RequestActions,
    void,
    { floorPlans: SpaceFloorPlan[] }
>;

function* getSpaceFloorPlansSaga({ locationId }: GetSpaceFloorPlans): GetSpaceFloorPlanSaga {
    try {
        const { floorPlans } = yield call(getFloorPlans, locationId);
        yield put(getSpaceFloorPlansSuccess(floorPlans, locationId));
        yield put(requestSuccess(RequestType.GetSpaceFloorPlans, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.GetSpaceFloorPlans, RequestActionType.Error));
    }
}

type CreateSpaceFloorPlanSaga = Generator<
    CallEffect | PutEffect<CreateSpaceFloorPlanSuccess> | CallEffect<{ id: string }> | RequestActions,
    void,
    { id: string }
>;

function* createSpaceFloorPlanSaga({ payload, locationId }: CreateSpaceFloorPlan): CreateSpaceFloorPlanSaga {
    try {
        const { id } = yield call(uploadFloorPlan, payload, locationId);
        yield put(createSpaceFloorPlanSuccess(id, payload, locationId));
        yield put(requestSuccess(RequestType.CreateSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.CreateSpaceFloorPlan, RequestActionType.Error));
    }
}

type GetFloorPlanDetailsSaga = Generator<CallEffect<FloorPlanData> | PutEffect | RequestActions, void, FloorPlanData>;
function* getFloorPlanDetailsSaga({ locationId, floorPlanId }: GetFloorPlanData): GetFloorPlanDetailsSaga {
    try {
        const floorPlanData = yield call(getFloorPlanDetails, locationId, floorPlanId);
        yield put(getFloorPlanDataSuccess(floorPlanData, locationId, floorPlanId));
        yield put(requestSuccess(RequestType.GetFloorPlanData, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.GetFloorPlanData, RequestActionType.Error));
    }
}

type EditFloorPlanSaga = Generator<CallEffect | PutEffect | RequestActions, void, void>;

function* editFloorPlanSaga({ payload, floorPlanId, locationId }: EditSpaceFloorPlan): EditFloorPlanSaga {
    try {
        yield call(editFloorPlan, payload, floorPlanId, locationId);
        yield put(editSpaceFloorPlanSuccess(payload, floorPlanId, locationId));
        yield put(requestSuccess(RequestType.EditSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield call(displayAlertBoxSaga, `ErrorCodes.${asErrorType.error}`, false, true);
        yield put(requestError(asErrorType, RequestType.EditSpaceFloorPlan, RequestActionType.Error));
    }
}

type DeleteFloorPlanSaga = Generator<CallEffect | PutEffect | RequestActions, void, void>;
function* deleteFloorPlanSaga({ locationId, floorPlanId }: DeleteSpaceFloorPlan): DeleteFloorPlanSaga {
    try {
        yield call(deleteFloorPlan, floorPlanId, locationId);
        yield put(deleteSpaceFloorPlanSuccess(floorPlanId, locationId));
        yield put(requestSuccess(RequestType.DeleteSpaceFloorPlan, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.DeleteSpaceFloorPlan, RequestActionType.Error));
    }
}

export default function* floorPlanSpaceSaga(): Generator {
    yield all([
        takeEvery(FloorPlanSpaceActionType.GetSpaceFloorPlansInit, getSpaceFloorPlansSaga),
        takeEvery(FloorPlanSpaceActionType.CreateSpaceFloorPlanInit, createSpaceFloorPlanSaga),
        takeEvery(FloorPlanSpaceActionType.GetFloorPlanDataInit, getFloorPlanDetailsSaga),
        takeEvery(FloorPlanSpaceActionType.EditSpaceFloorPlanInit, editFloorPlanSaga),
        takeEvery(FloorPlanSpaceActionType.DeleteSpaceFloorPlanInit, deleteFloorPlanSaga),
    ]);
}
