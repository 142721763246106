import { PropsWithoutRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { t as txt } from 'i18next';
import { dateFormats, sensorUnits, tooltipBorderColor, colors } from 'commons/src/constants';
import { timeFormatter } from 'commons/src/features/devicePage/graphDataFunctions';
import { dateTime, dayDate, setXAxisLabelFormat, yVal } from 'commons/src/features/devicePage/SensorConfig';
import { SelectedPeriod, SensorThresholds } from 'commons/src/models/commonTypeScript';

export type SpaceGraphData = {
    data: [number, number | null][];
    id: string;
    name: string;
    color: string;
    type?: string;
    lineWidth?: number;
    showInLegend?: boolean;
    opacity?: number;
    yAxis?: number;
    dashStyle?: string;
};
type Props = {
    data: SpaceGraphData[];
    sensorLevelPlotBands: { color: string; from: number; to: number }[];
    usageHoursPlotBands: { color: string; from: number; to: number }[];
    selectedInterval: SelectedPeriod;
    dateFormat: keyof typeof dateFormats;
    sensorUnit: SensorThresholds;
    graphStartAndEnd: { max: number; min: number };
    plotLines: { zIndex: number; color: string; dashStyle: string; value: number; width: number }[];
    compareSensorUnit?: SensorThresholds;
};

const mainChartId = 'spaceGraph';
export const rangeSeriesName = 'rangeSeries';
const getUpperPlotBand = (plotBands: { color: string; from: number; to: number }[]): number => {
    const upperPlotBand = plotBands?.reduce((acc, { from }) => (from > acc ? from : acc), 0);
    return upperPlotBand * 1.5;
};

const getTooltipSensorValues = (
    {
        series: { name, options: seriesOptions },
        point: { y, options },
    }: {
        series: Highcharts.Series;
        point: Highcharts.Point;
    },
    sensorUnit: SensorThresholds,
    compareUnit?: SensorThresholds
): { id: string; name: string; value: string } => {
    const seriesId = seriesOptions.id as string;
    const sensor = seriesId.split('-')[0];

    const indexOfFirstDash = seriesId.indexOf('-');

    let segmentId = seriesId.substring(indexOfFirstDash + 1);
    const isOutdoor = segmentId.includes('outdoor');
    if (!isOutdoor) {
        const indexOfLastDash = segmentId.lastIndexOf('-');
        segmentId = segmentId.substring(0, indexOfLastDash);
    }

    let value;
    const unit = compareUnit?.type === sensor ? compareUnit.unit : sensorUnit.unit;
    if (unit === 'occ') {
        value =
            name === rangeSeriesName
                ? `${options.low} - ${options.high} ${txt(`SensorUnit.${unit}` || '')}`
                : `${y} ${txt(`SensorUnit.${unit}` || '')}`;
    } else if (name === rangeSeriesName) value = '';
    else if (unit === 'minutes') {
        value = `${timeFormatter(y)}`;
    } else {
        value = `${yVal(y, unit)} ${sensorUnits[unit]}`;
    }
    const dontShowSensor = !compareUnit || isOutdoor;

    return {
        id: segmentId,
        name,
        value: dontShowSensor ? value : `${txt(sensor)}: ${value}`,
    };
};

const getTooltipText = (
    points: Highcharts.TooltipFormatterContextObject[],
    sensorUnit: SensorThresholds,
    compareUnit?: SensorThresholds
): string[] => {
    const getPointData = points?.map(point => getTooltipSensorValues(point, sensorUnit, compareUnit)) || [];
    const pointText = getPointData.reduce((acc, pointData) => {
        const deviceInState = acc[pointData.id];
        if (deviceInState) {
            acc[pointData.id] = { name: pointData.name, value: `${deviceInState.value}<br/>${pointData.value}` };
        } else {
            acc[pointData.id] = { name: pointData.name, value: pointData.value };
        }
        return acc;
    }, {} as { [id: string]: { name: string; value: string } });
    const indexOfOutdoor = Object.keys(pointText).findIndex(id => id.includes('outdoor'));
    return Object.values(pointText).map(({ name, value }, i) => {
        return compareUnit && indexOfOutdoor !== i ? `${name}<br/>${value}<br/>` : `${name}: ${value}<br/>`;
    });
};

const yAxisFormatter = (value: number, sensorUnit: keyof typeof sensorUnits): string => {
    if (sensorUnit === 'minutes') return timeFormatter(value);
    const yValue = Math.round(value * 10) / 10;
    return yValue % 1 === 0 || sensorUnit === sensorUnits.bq
        ? Highcharts.numberFormat(yValue, 0)
        : Highcharts.numberFormat(yValue, 1);
};

const spaceGraphConfig = ({
    data,
    sensorLevelPlotBands,
    usageHoursPlotBands,
    selectedInterval,
    dateFormat,
    sensorUnit,
    graphStartAndEnd,
    plotLines,
    compareSensorUnit,
}: Props): PropsWithoutRef<HighchartsReact.Props> => ({
    chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: 'line',
        spacingBottom: 5,
        height: 350,
        style: {
            fontFamily: 'OpenSans-Regular',
        },
    },
    id: mainChartId,
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        tickLength: 0,
        tickPixelInterval: 130,
        plotBands: usageHoursPlotBands,
        lineColor: 'transparent',
        min: graphStartAndEnd.min,
        max: graphStartAndEnd.max,
        labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                const { value } = this;
                return Highcharts.dateFormat(setXAxisLabelFormat(selectedInterval, dateFormat), value as number);
            },
        },
    },
    yAxis: [
        {
            title: { enabled: false },
            plotBands: sensorLevelPlotBands,
            max:
                data.length === 0 && sensorLevelPlotBands.length > 0
                    ? getUpperPlotBand(sensorLevelPlotBands)
                    : undefined,
            min: data.length === 0 ? 0 : undefined,
            endOnTick: false,
            startOnTick: false,
            plotLines,
            gridLineWidth: 0,
            tickPixelInterval: 40,
            labels: {
                formatter(this: { value: number }): string {
                    const { value } = this;
                    return yAxisFormatter(value, sensorUnit?.unit);
                },
            },
        },
        {
            gridLineWidth: 0,
            title: {
                text: null,
            },
            labels: {
                formatter(this: { value: number }): string {
                    const { value } = this;
                    return compareSensorUnit ? yAxisFormatter(value, compareSensorUnit?.unit) : '';
                },
            },
            opposite: true,
        },
    ],
    tooltip: {
        borderColor: tooltipBorderColor,
        style: {
            color: colors.white,
        },
        enabled: true,
        hideDelay: 0,
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
            const { resolution } = selectedInterval;
            const { x, points } = this;
            const seriesValues = getTooltipText(points || [], sensorUnit, compareSensorUnit);
            return [
                dayDate(x as number, dateFormat, resolution),
                dateTime(x as number, dateFormat, resolution),
                ...seriesValues,
            ].join('<br/>');
        },
    },
    plotOptions: {
        series: {
            animation: { duration: 600, direction: 'bottom' },
            showInLegend: data.length > 0,
            lineWidth: 2,
            shadow: false,
            marker: {
                enabled: false,
                symbol: 'circle',
            },
            states: {
                hover: { lineWidthPlus: 0 },
            },
        },
        column: {
            borderRadius: 4,
        },
    },
    legend: {
        symbolWidth: 20,
        labelFormatter: function labelFormatter(): string {
            const seriesId = this.userOptions.id;
            const seriesSensor = seriesId.split('-')[0];
            const isOutdoor = seriesId.includes('outdoor');
            return compareSensorUnit && !isOutdoor ? `${this.name} (${txt(seriesSensor)})` : this.name;
        },
    },
    series:
        data.length > 0
            ? data.map(seriesData => ({
                  type: 'line',
                  connectNulls: false,
                  ...seriesData,
              }))
            : [{ data: [], id: '', name: '', color: '' }],
});

export default spaceGraphConfig;
