import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { getFloorPlanData, getSpaceFloorPlans } from '../../actions/floorPlanSpaceActions';
import { paths } from '../../constants';
import { Store } from '../../reducers';
import AddFirstFloorPlan from './addFloorPlan/AddFirstFloorPlan';
import FloorPlanNavigation from './FloorPlanNavigation';
import FloorPlanView from './floorPlanViewer/FloorPlanView';
import styles from './SpaceFloorPlan.module.scss';

const SpaceFloorPlans = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const { buildingId } = useParams() as { buildingId: string };
    const [addingNewFloor, setAddingNewFloor] = useState(false);
    const floorPlans = useSelector((state: Store) => state.floorPlansSpace.floorPlans);
    const floorPlanData = useSelector((state: Store) => state.floorPlansSpace.floorData);
    const { loading, error } = useSelector((state: Store) => state.requests.GET_SPACE_FLOOR_PLANS);
    const { loading: addFloorLoading, error: addFloorError } = useSelector(
        (state: Store) => state.requests.CREATE_SPACE_FLOOR_PLAN
    );

    const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string | undefined>(undefined);
    const onGoBack = (): void => {
        navigate(generatePath(`/${paths.spaces}`, { buildingId }));
    };

    const fetchFloorData = (floorId?: string): void => {
        if (floorId && !floorPlanData[floorId]) {
            dispatch(getFloorPlanData(buildingId, floorId));
        }
    };

    useEffect(() => {
        dispatch(getSpaceFloorPlans(buildingId));
    }, []);

    useEffect(() => {
        if (addingNewFloor && !addFloorLoading && !addFloorError) {
            setAddingNewFloor(false);
            setSelectedFloorPlanId(floorPlans[floorPlans.length - 1].id);
        }
    }, [addFloorLoading]);

    const onSelectAddNewFloor = (): void => {
        setAddingNewFloor(true);
        setSelectedFloorPlanId(undefined);
    };

    const onSelectFloorPlan = (floorId?: string): void => {
        fetchFloorData(floorId);
        setSelectedFloorPlanId(floorId);
        setAddingNewFloor(false);
    };

    if (!loading && floorPlans.length === 0) {
        return <AddFirstFloorPlan locationId={buildingId} />;
    }

    return (
        <PageWrapper pageType="wide">
            <SubHeader actionButtons={[]} backTitle="Return" onGoBack={onGoBack} />
            {error && <ResponseBox text={txt(`ErrorCodes.${error.error}`)} />}
            <ReactPlaceholder ready={!loading} customPlaceholder={fullWidthListPlaceholder}>
                <div className={styles.wrapper}>
                    <FloorPlanNavigation
                        selectedFloorPlanId={selectedFloorPlanId}
                        floorPlans={floorPlans}
                        setSelectedFloorPlanId={onSelectFloorPlan}
                        setAddingNewFloor={onSelectAddNewFloor}
                        addingNewFloor={addingNewFloor}
                    />
                    <FloorPlanView
                        floorPlanId={selectedFloorPlanId}
                        addingNewFloor={addingNewFloor}
                        floorPlanData={floorPlanData[selectedFloorPlanId || '']}
                        locationId={buildingId}
                    />
                </div>
            </ReactPlaceholder>
        </PageWrapper>
    );
};
export default SpaceFloorPlans;
