import React, { ReactElement, useState } from 'react';
import L, { latLngBounds } from 'leaflet';
import { MapContainer, ZoomControl } from 'react-leaflet';
import FloorMapImage from '../../floorplan/floorMap/FloorMapImage';
import styles from './FloorMap.module.scss';

type Props = {
    image: string;
};
const FloorMap = ({ image }: Props): ReactElement => {
    const [bounds, setBounds] = useState<L.LatLngBounds>(
        latLngBounds([
            [0, 0],
            [1000, 1000],
        ])
    );
    const [mapIsReady, setMapIsReady] = useState(false);

    const updateBounds = (): void => {
        // eslint-disable-next-line no-undef
        const displayedImage = new Image();
        displayedImage.onload = (): void => {
            const { width, height } = displayedImage;
            const newBounds = latLngBounds([
                [0, width],
                [-height, 0],
            ]);
            setBounds(newBounds);
        };
        displayedImage.src = image;
    };

    const onMapReady = (): void => {
        updateBounds();
        setMapIsReady(true);
    };

    return (
        <div className={styles.mapWrapper}>
            <MapContainer
                zoomControl={false}
                scrollWheelZoom={false}
                crs={L.CRS.Simple}
                bounds={bounds}
                className={styles.container}
                zoomDelta={0.2}
                zoomSnap={0.1}
                minZoom={-4}
                whenReady={onMapReady}
            >
                <FloorMapImage bounds={bounds} floorPlanImage={image} mapReady={mapIsReady} onMapReady={onMapReady} />
                <ZoomControl position="bottomright" />
            </MapContainer>
        </div>
    );
};

export default FloorMap;
