import { createReducer } from '@reduxjs/toolkit';
import {
    CreateSpaceFloorPlanSuccess,
    DeleteSpaceFloorPlanSuccess,
    EditSpaceFloorPlanSuccess,
    FloorPlanSpaceActionType,
    GetFloorPlanDataSuccess,
    GetSpaceFloorPlansSuccess,
} from '../actions/floorPlanSpaceActions';
import { FloorPlanData, SpaceFloorPlan } from '../models/spaceFloorPlanModels';

export type SpaceFloorPlanState = {
    floorPlans: SpaceFloorPlan[];
    locationId?: string;
    floorData: { [floorPlanId: string]: FloorPlanData };
};

const initialState: SpaceFloorPlanState = {
    floorPlans: [],
    locationId: undefined,
    floorData: {},
};

/* eslint-disable no-param-reassign */
const spaceFloorPlanReducer = createReducer(initialState, builder => {
    builder
        .addCase(FloorPlanSpaceActionType.GetSpaceFloorPlansSuccess, (state, action: GetSpaceFloorPlansSuccess) => {
            state.floorPlans = action.floorPlans;
            state.locationId = action.locationId;
        })
        .addCase(FloorPlanSpaceActionType.CreateSpaceFloorPlanSuccess, (state, action: CreateSpaceFloorPlanSuccess) => {
            const correctLocationInState = state.locationId === action.locationId;
            const newFloorPlan: SpaceFloorPlan = {
                id: action.id,
                name: action.payload.name,
                floor: action.payload.floor,
                image: action.payload.image,
            };
            if (correctLocationInState) {
                state.floorPlans.push(newFloorPlan);
            } else {
                state.floorPlans = [newFloorPlan];
                state.locationId = action.locationId;
            }
            state.floorData[action.id] = { image: action.payload.image };
        })
        .addCase(FloorPlanSpaceActionType.GetFloorPlanDataSuccess, (state, action: GetFloorPlanDataSuccess) => {
            state.floorData[action.floorPlanId] = action.floorPlanData;
        })
        .addCase(FloorPlanSpaceActionType.DeleteSpaceFloorPlanSuccess, (state, action: DeleteSpaceFloorPlanSuccess) => {
            state.floorPlans = state.floorPlans.filter(floorPlan => floorPlan.id !== action.floorPlanId);
            delete state.floorData[action.floorPlanId];
        })
        .addCase(FloorPlanSpaceActionType.EditSpaceFloorPlanSuccess, (state, action: EditSpaceFloorPlanSuccess) => {
            const floorPlanIndex = state.floorPlans.findIndex(floorPlan => floorPlan.id === action.floorPlanId);
            state.floorPlans[floorPlanIndex] = action.payload;
        });
});

export default spaceFloorPlanReducer;
